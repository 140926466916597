//import { resetHeaders, privateAgent} from "../configurations/AxiosAgent";
import { LOCAL_STORAGE_KEY } from "../configurations/Constants";
import { store } from "../ConfigureStore";
import { loginSuccess, logoutRequest } from "../actions/Auth";

const login = (auth, cb) => {
  //let auth = res.data.data;
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({ ...auth.user, access_token: auth.access_token })
  );

  // privateAgent.defaults.headers = {
  //     ...privateAgent.defaults.headers,
  //     "access_token": "Bearer " + auth.access_token,
  //     //"user_id": auth.user_id
  // }

  // console.log("privateAgent.defaults.headers::",privateAgent.defaults.headers)
  if (cb) cb();
  store.dispatch(loginSuccess(auth.user));
};

const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);

  //resetHeaders(privateAgent);

  store.dispatch(logoutRequest());
};

const isLogin = () => {
  if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
    //private agent headers set
    return true;
  }

  return false;
};

const redirect = (...params) => {
  //let history = store.getState().App.history;
  //console.log("HISTORY", history)
  //history.push(...params);
  // store.dispatch(redirect(url))
};

function urlToBase64(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

function clearStorageItems(keys) {
  keys?.length && [].concat(keys).forEach(key => window.localStorage.removeItem(key));
}

export { login, logout, isLogin, redirect, urlToBase64, clearStorageItems };
