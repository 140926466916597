import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  changePasswordSuccess,
  changePasswordError,
  contactSuccess,
} from "../actions/App";
import {
  CHANGE_PASSWORD_REQUEST,
  CONTACT_REQUEST,
} from "../configurations/Types";
import {
  // FAQS_ENDPOINT,
  CONTACT_ENDPOINT,
  USERS_URL,
} from "../configurations/Constants";
import info from "../components/message/index";
import {
  // privateAgent,
  publicAgent,
  CancelToken,
} from "../configurations/AxiosAgent";
import moment from "moment";
import { client, gql } from "configurations/apollo";

const CHANGE_PASSWORD = gql`
  mutation UPDATE_PASSWORD(
    $old_password: String!
    $password: String!
    $current_time: String!
  ) {
    updatePassword(
      input: {
        old_password: $old_password
        password: $password
        current_time: $current_time
      }
    ) {
      status
      message
    }
  }
`;

const changePasswordApi = (body) => {
  //console.log("changePasswordApi", body);
  return client.mutate({
    mutation: CHANGE_PASSWORD,
    variables: {
      old_password: body.old_password,
      password: body.password,
      //password_confirmation: body.confirm_password,
      current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    },
  });
};

const contactUsApi = (body, token) => {
  //console.log("contactUsApi", body, token);
  return publicAgent.post(`${USERS_URL}${CONTACT_ENDPOINT}`, body, {
    cancelToken: token.token,
  });
};

// ...

// function* test() {
//   yield 10;
//   return 99;
// }

let token;

// Our worker Saga: will perform the async increment task
export function* changePassword(action) {
  // console.log("IN CHANGE PASSWORD", action);
  let res;
  try {
    let { confirm_password, ...properBody } = action.body;
    res = yield changePasswordApi(properBody);
    // console.log("changePassword", res);
    info("success", `Your password has been reset`);
    yield put(changePasswordSuccess(res));
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      // console.log("ERROR", e);
      info("error", `${e.response.data.error.message}`);
    }
    yield put(changePasswordError(e));
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
  // console.log("In saga", res);
  //yield put({ type: 'INCREMENT' })
}

export function* contactEmail(action) {
  // console.log("CONTACT_US", action);
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    let res = yield contactUsApi({ ...action.body }, token);
    yield put(contactSuccess());
    // console.log("res: ", res);
    info("success", `${res.data.data.message}`);
    if (action.cb) {
      action.cb();
    }
  } catch (err) {
    if (err.response) {
      //console.log("ERROR", err);
      info("error", `${err.response.data.error.message}`);
    }
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAppActions() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeLatest(CONTACT_REQUEST, contactEmail);
}
