import { put, takeLatest, cancelled } from "redux-saga/effects";
import { getReport as getReportAction } from "../actions/Report";
import { GENERATE_PDF_ENDPOINT, API_REST_URL } from "configurations/Constants";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";
const getReportApi = (body) => {
  return privateAgent.post(`${API_REST_URL}${GENERATE_PDF_ENDPOINT}`, body);
};

//let token;

// Our worker Saga: will perform the async increment task
export function* getReport(action) {
  console.log("IMAGES SAGA", action);

  let { body, cb } = action.payload;
  let res;
  try {
    console.log("REPORT SAGA", action);

    const {
      data: {
        data: { url },
      },
    } = yield getReportApi(body);
    console.log("report", res);

    //info("success", `Image/s uploaded`);
    if (cb) {
      cb(null, url);
    }
  } catch (e) {
    console.log(e);
    try {
      info("error", `${e.response.data.error.message}`);
    } catch (e) {}
    if (cb) {
      cb(new Error("Error occured"));
    }
  } finally {
    if (yield cancelled()) {
      // console.log("CANCELLED");
    }
  }
  // console.log("In saga", res);
  //yield put({ type: 'INCREMENT' })
}

export function* watchReportActions() {
  yield takeLatest(getReportAction.toString(), getReport);
}
