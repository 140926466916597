import {
  TOGGLE_MODE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_ERROR,
  SET_HISTORY,
  REDIRECT,
} from "../configurations/Types";

const INITIAL_STATE = {
  history: null,
  mode: "light",
  loading: false,

  backButtonLabel: "Go Back",
  externalScreen: false,

  contactFormLoading: false,

  // faqs: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MODE: {
      return Object.assign({}, state, {
        mode: state.mode === "light" ? "dark" : "light",
      });
    }
    case CHANGE_PASSWORD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        profileDetails: action.payload,
        loading: false,
      });
    }
    case CHANGE_PASSWORD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case CONTACT_REQUEST: {
      return Object.assign({}, state, { contactFormLoading: true });
    }
    case CONTACT_SUCCESS: {
      return Object.assign({}, state, { contactFormLoading: false });
    }
    case CONTACT_ERROR: {
      return Object.assign({}, state, { contactFormLoading: false });
    }
    case SET_HISTORY: {
      return Object.assign({}, state, { history: action.payload });
    }
    case REDIRECT: {
      console.log("REDIRECT action", action);
      let url = action.payload;

      let externalScreen = false;
      let backButtonLabel = "Go Back";

      switch (url) {
        case "/add-bank": {
          externalScreen = true;
          backButtonLabel = "Add Bank Account";
          break;
        }
        default: {
        }
      }

      //for non exact routes
      if (url.includes("/survey/")) {
        externalScreen = true;
        //backButtonLabel = "Manage Fire and Risk Assessment";
      }
      if (url.includes("/survey/2")) {
        externalScreen = true;
        backButtonLabel = "Manage Fire and Risk Assessment";
      }
      if (url.includes("/survey/1")) {
        externalScreen = true;
        backButtonLabel = "Manage Health and Safety";
      }
      if (url.includes("/survey/fire-risk-assessment/")) {
        externalScreen = true;
        backButtonLabel = "Review Data";
      }

      // console.log(externalScreen)

      return Object.assign({}, state, { externalScreen, backButtonLabel });
    }

    default: {
      return state;
    }
  }
};
