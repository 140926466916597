import { applyMiddleware, createStore
  //, compose  
} from 'redux'
import createSagaMiddleWare from 'redux-saga'

import rootReducer from "./reducers";
import rootSaga from "./sagas";

// export default function configureStore(preloadedState) {

const sagaMiddleware = createSagaMiddleWare();

//const middlewares = [loggerMiddleware, thunkMiddleware]
//const middlewareEnhancer = applyMiddleware(...middlewares)

//const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
//const composedEnhancers = compose(...enhancers)

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
// compose(
//   applyMiddleware(sagaMiddleware)
//   ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// );

sagaMiddleware.run(rootSaga);
