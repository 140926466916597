import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Loader from "./components/loader";

const Login = lazy(() => import("./views/auth/LoginCard"));
// const LandingScreen = lazy(() => import("./views/landingpage/index"));
const ForgotPassword = lazy(() => import("./views/auth/ForgotPassword"));
const VerificationCode = lazy(() => import("./views/auth/VerificationCode"));
const ResetPassword = lazy(() => import("./views/auth/ResetPassword"));

const Shell = lazy(() => import("./views/shell"));

class Routes extends React.Component {
  render() {
    const { isAuthenticated } = this.props;
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          {/* {!isAuthenticated && <Route exact path="/home" component={LandingScreen} />} */}
          {!isAuthenticated && <Route exact path="/login" component={Login} />}
          {!isAuthenticated && <Route exact path="/forgot-password" component={ForgotPassword} />}
          {!isAuthenticated && <Route exact path="/verify-code" component={VerificationCode} />}
          {!isAuthenticated && <Route exact path="/reset-password" component={ResetPassword} />}

          {isAuthenticated && <Route path="/" component={Shell} />}

          <Route path="*" render={() => <Redirect to={isAuthenticated ? "/" : "/login"} />} />
        </Switch>
      </Suspense>
    );
  }
}

// const ProtectedRoute = ({ enabled, ...props }) => (
//   enabled && <Route {...props} />
// )

export default withRouter(
  connect((storeState) => ({
    isAuthenticated: storeState.Auth.isAuthenticated,
  }))(Routes)
);
